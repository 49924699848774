import React, { Fragment, useContext, useMemo } from "react";
import { editableCellCreator } from "../../../ui/BWGrid/EditableCell";
import { inputs } from "../../../inputs/inputConfigs";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  StyledEdit,
  SelectedPrice,
} from "../../Quoting/PurchaseOrderList/components";
import Subheader from "../../../ui/Typography/Subheader";
import BWGrid from "../../../ui/BWGrid/BWGrid";
import GridTable from "../../../ui/BWGrid/GridTable";
import propTypes from "../../../../constants/propTypes";
import Price from "../../../ui/Price";
import {
  PORevisionContext,
  RevisionReferenceContext,
} from "../../../../withPORevision";
import useCurrencyFormatter from "../../../hooks/useCurrencyFormatter";
import { LabeledTextContext } from "../../../../withPORevision";
import { cleanCurrencyValue } from "../../../../utils/currencyFormatter";

const rowInputs = currency => ({
  amount: {
    ...inputs.price,
    name: "amount",
    inputProps: {
      currency,
      maskProps: { allowNegative: true },
    },
    InputProps: {
      ...inputs.price.InputProps,
      endAdornment: <StyledEdit />,
    },
  },
});

const StyledSubheader = styled(Subheader)`
  display: block;
  padding: 22px 22px 20px;
`;

export const ColumnWrapper = styled.div`
  position: relative;
  ${({ wasUpdated }) =>
    wasUpdated &&
    `
  &:before {
    position: absolute;
    content: "\\25CF";
    font-size: 10px;
    color: #ff9100;
    top: -11px;
    left: -6px;
    overflow: visible;
  }
`}
`;

export const Column = ({ row, path, isEditable, currency }) => {
  const { reference } = useContext(RevisionReferenceContext);
  const { isActive, isUpdated } = useContext(PORevisionContext);
  const name = row.attr;
  const wasUpdated = useMemo(() => {
    if (!isActive) return false;
    return isUpdated(reference, name);
  }, [isActive, isUpdated, reference, name]);

  return (
    <ColumnWrapper wasUpdated={wasUpdated}>
      {isEditable ? (
        <SelectedPrice price={row[path]} currency={currency} />
      ) : (
        <Price number={row[path]} currency={currency} />
      )}
    </ColumnWrapper>
  );
};

Column.propTypes = {
  row: PropTypes.shape({}),
  path: PropTypes.string,
  isEditable: PropTypes.bool,
  currency: PropTypes.object,
};

export const columnOptions = (isEditable = true, isLabeled, currency) => ({
  type: {
    bold: true,
  },
  extendedCost: {
    fill: true,
  },
  amount: {
    render: (row, path) =>
      row && (
        <Column
          row={row}
          path={path}
          currency={currency}
          isEditable={
            row.attr == "clientBudgetCents"
              ? !isLabeled
              : isEditable && !isLabeled
          }
        />
      ),
    editableOptions: {
      beforeSave: value => cleanCurrencyValue(value * 100),
      formatValue: value => cleanCurrencyValue(value / 100),
    },
    editable: true, // Check table components to know if this is actually editable
  },
});

export const getSpecPricings = (
  formatter,
  { priceCents, clientBudgetCents, totalQuantityWithoutOverage }
) => [
  {
    id: "1",
    type: "Client Budget",
    rowMenuText: "Change Client Budget",
    attr: "clientBudgetCents",
    addOnClick: true,
    amount: clientBudgetCents / 100,
    extendedCost: formatter.format(
      (clientBudgetCents * totalQuantityWithoutOverage) / 100
    ),
  },
  {
    id: "2",
    type: "Price",
    rowMenuText: "Change Price",
    addOnClick: true,
    attr: "priceCents",
    amount: priceCents / 100,
    extendedCost: formatter.format(
      (priceCents * totalQuantityWithoutOverage) / 100
    ),
  },
];

export const getRowMenu = openEditModal => row => [
  {
    text: row.rowMenuText,
    onClick: row.addOnClick && openEditModal,
  },
];

const PricingSectionPage = ({ spec, isEditable }) => {
  const isLabeled = useContext(LabeledTextContext);
  const currency = spec.projectCurrency?.currency;
  const currencyFormatter = useCurrencyFormatter(currency);
  const specPricing = getSpecPricings(currencyFormatter, spec);

  return (
    <Fragment>
      <StyledSubheader>Pricing</StyledSubheader>
      <BWGrid
        rows={specPricing}
        gridConfig={{
          totalRows: specPricing.length,
          pageSize: 0,
        }}
        tableComponents={{
          CellComponent: editableCellCreator(
            rowInputs(currency),
            ({ rowId }) => {
              return rowId === 0 ? "clientBudgetCents" : "priceCents";
            },
            (row, tableRow) =>
              tableRow.rowId == 0 ? !isLabeled : isEditable && !isLabeled
          ),
        }}
      >
        <GridTable
          columns={["type", "amount", "extendedCost"]}
          columnOptions={columnOptions(isEditable, isLabeled, currency)}
        />
      </BWGrid>
    </Fragment>
  );
};

PricingSectionPage.propTypes = {
  spec: propTypes.spec,
  isEditable: PropTypes.bool,
};

export default PricingSectionPage;
