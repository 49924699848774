import React from "react";
import styled from "styled-components";
import BWModels from "benjaminwest-models";

import { FormGrid, FormikForm } from "../../../../forms";
import { ActionButtons } from "../../../../forms/ActionButtons";
import { inputs } from "../../../../inputs/inputConfigs";
import { projectServiceDataComponentId } from "../ProjectServiceContainer";

const Wrapper = styled.div``;

const fields = {
  groups: [
    {
      items: [
        {
          input: inputs.name,
          grid: { xs: 12 },
        },
      ],
    },
  ],
};

export const EditProjectService = ({
  projectService,
  handleEditProjectService,
  closeModalDialog,
}) => {
  return (
    <FormikForm
      initialValues={{
        name: projectService.name,
        description: projectService.description,
      }}
      onSubmit={handleEditProjectService}
      validationSchema={BWModels.loadSchema("ProjectService")}
    >
      {({ handleSubmit, ...formikProps }) => (
        <Wrapper>
          <FormGrid fields={fields} {...formikProps} />
          <ActionButtons
            onSend={handleSubmit}
            onCancel={closeModalDialog}
            sendButtonText="Save"
            listeners={[projectServiceDataComponentId]}
            isModal
          />
        </Wrapper>
      )}
    </FormikForm>
  );
};
