import React, { Fragment, useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import BWModels from "benjaminwest-models";
import _noop from "lodash/noop";
import { isEmpty } from "lodash";

import { Content } from "../../layout/Content";
import { Wrapper, MainSection, DetailSection } from "./components";
import { FormikForm } from "../../forms";
import propTypes from "../../../constants/propTypes";
import AdditionalInformation from "./AdditionalInformation";
import DetailAndCycleContainer from "./HeaderSection/DetailAndCycleContainer";
import CheckRequestDetailsSection from "./CheckRequestDetailsSection";
import InvoiceDataSection from "./InvoiceDataSection";
import POSection from "./POSection";
import RemarksSection from "./RemarksSection";
import Specs from "./Specs";
import DirtyFormPrompt from "../../forms/DirtyFormPrompt";
import { LabeledTextContext } from "../../../withPORevision";
import { useIsProjectClosed } from "../../hooks/useIsProjectClosed";
import { LoaderContext } from "../../ui/Loader";

const InvoiceDetailPage = ({
  onUpdateInvoice,
  onOpenNotesModal,
  nextItemRoute,
  lastItemRoute,
  invoice,
  projectId,
  generateAndDownloadReport,
  dataComponentId,
  allChangesLocked,
}) => {
  const [setCurrentCell, setCurrentCellFunc] = useState(() => _noop);

  const detailSectionRef = useRef();
  const isProjectClosed = useIsProjectClosed();
  const readOnly = useContext(LabeledTextContext) || isProjectClosed;
  const { loading: isLoading } = useContext(LoaderContext);

  const projectCurrency = invoice?.purchaseOrder?.projectCurrency || {};

  const openInvoiceDataInput = () => {
    setCurrentCell({ rowId: 3, columnId: 1 });
  };

  return (
    <Content>
      <FormikForm
        initialValues={{ glCodes: [], ...invoice }}
        onSubmit={onUpdateInvoice}
        validationSchema={BWModels.loadSchema("Invoice")}
        enableReinitialize
        isPatch
        ignoreCache
      >
        {({ handleSubmit, ...formikProps }) => {
          const values = formikProps.values;
          const updateInvoice = (values = {}) => {
            onUpdateInvoice(
              { ...formikProps.values, ...values },
              formikProps,
              !isEmpty(values)
            );
          };
          const updateInvoiceIgnoreParameters = () => updateInvoice();
          return (
            <Fragment>
              <DetailAndCycleContainer
                invoice={invoice}
                nextItemRoute={nextItemRoute}
                lastItemRoute={lastItemRoute}
                onOpenNotesModal={onOpenNotesModal}
                projectId={projectId}
                generateAndDownloadReport={generateAndDownloadReport}
              />
              <MainSection>
                <Wrapper>
                  <DetailSection ref={detailSectionRef}>
                    <CheckRequestDetailsSection
                      {...formikProps}
                      isProjectClosed={isProjectClosed}
                      okToIssue={values.okToIssue}
                      updateInvoice={updateInvoice}
                      allChangesLocked={allChangesLocked}
                      updateInvoiceIgnoreParameters={
                        updateInvoiceIgnoreParameters
                      }
                      isLoading={isLoading}
                    />
                    <POSection {...formikProps} />
                  </DetailSection>
                  <AdditionalInformation
                    invoice={invoice}
                    maxHeight={detailSectionRef.current?.clientHeight}
                    updateInvoice={updateInvoice}
                  />
                </Wrapper>
                <Specs
                  {...formikProps}
                  specs={values?.invoiceSpecs}
                  readOnly={readOnly}
                  projectCurrency={projectCurrency}
                  openInvoiceDataInput={openInvoiceDataInput}
                  updateInvoice={updateInvoiceIgnoreParameters}
                  allChangesLocked={allChangesLocked}
                />
                <InvoiceDataSection
                  {...formikProps}
                  readOnly={readOnly}
                  setCurrentCell={setCurrentCell}
                  setCurrentCellFunc={setCurrentCellFunc}
                  projectCurrency={projectCurrency}
                  updateInvoice={updateInvoiceIgnoreParameters}
                  allChangesLocked={allChangesLocked}
                  isLoading={isLoading}
                />
                <RemarksSection
                  {...formikProps}
                  setCurrentCell={setCurrentCell}
                  updateInvoice={updateInvoiceIgnoreParameters}
                  allChangesLocked={allChangesLocked}
                  isLoading={isLoading}
                />
              </MainSection>
              <DirtyFormPrompt
                onSubmit={updateInvoice}
                dataComponentId={dataComponentId}
              />
            </Fragment>
          );
        }}
      </FormikForm>
    </Content>
  );
};

InvoiceDetailPage.propTypes = {
  invoice: propTypes.invoice,
  onUpdateInvoice: PropTypes.func.isRequired,
  onOpenNotesModal: PropTypes.func.isRequired,
  nextItemRoute: PropTypes.string,
  lastItemRoute: PropTypes.string,
  clientId: PropTypes.string.isRequired,
  dataComponentId: PropTypes.string.isRequired,
  allChangesLocked: PropTypes.bool,
};

export default InvoiceDetailPage;
