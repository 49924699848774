import {
  processCreateRequestStatus,
  processDeleteRequestStatus,
  processUpdateRequestStatus,
} from "../../../../utils/dataComponentUtils";
import { handleSuccess, handleError } from "./AreaRequestHandlers";

export const onCreateArea = (
  area,
  projectId,
  areaDataComponent,
  areasDataComponentId,
  performCreateRequest
) => {
  area.projectId = projectId;

  delete area.numberOfSpecs;

  performCreateRequest(areasDataComponentId, area);
};
export const onUpdateArea = (
  area,
  areaDataComponent,
  areasDataComponentId,
  performUpdateRequest
) => {
  delete area.numberOfSpecs;
  delete area.areaType;

  performUpdateRequest(areasDataComponentId, area.id, area);
};
export const onDeleteArea = (
  areaId,
  areaDataComponent,
  areasDataComponentId,
  performDeleteRequest
) => {
  performDeleteRequest(areasDataComponentId, areaId);
};
const processSaveRequest = (
  preDataComponent,
  dataComponent,
  projectId,
  areaDataComponentId,
  { performRetrieveListRequest, showSnackNotificationAction, closeModalDialog }
) => {
  processCreateRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        performRetrieveListRequest,
        areaDataComponentId,
        projectId,
        showSnackNotificationAction,
        "The area has been saved successfully",
        closeModalDialog
      );
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
  processUpdateRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        performRetrieveListRequest,
        areaDataComponentId,
        projectId,
        showSnackNotificationAction,
        "The area has been updated successfully",
        closeModalDialog
      );
    },
    onError: response =>
      handleError(response.data, showSnackNotificationAction),
  });
  processDeleteRequestStatus(preDataComponent, dataComponent, {
    onSuccess: () => {
      handleSuccess(
        performRetrieveListRequest,
        areaDataComponentId,
        projectId,
        showSnackNotificationAction,
        "The area has been deleted successfully",
        closeModalDialog
      );
    },
    onError: response => {
      handleError(response.data, showSnackNotificationAction);
    },
  });
};
export const processRequests = (
  preDataComponent,
  dataComponent,
  projectId,
  areaDataComponentId,
  callbacks
) => {
  processSaveRequest(
    preDataComponent,
    dataComponent,
    projectId,
    areaDataComponentId,
    callbacks
  );
};
