import { useCallback, useRef, useContext, useEffect } from "react";
import React from "react";
import PropTypes from "prop-types";
import { Field, setIn } from "formik";
import { isFunction } from "lodash";

import FloatInput, {
  FloatInput as RawFloatInput,
} from "../../inputs/FloatInput";
import BWCell from "./gridInternalComponents/BWCell";
import { WithEditableCellContext } from "./withEditableCellController";

import { hasError } from "../../../utils/formValidationUtils";
import { EditableCellError, EditableWrapper } from "./components";
import { buildPressEventHandlers } from "../../../utils/eventUtils";

const getInitialValue = (formatValue, value) => {
  return formatValue ? formatValue(value) : value;
};

const displayFieldError = (getInputError, tableRow, column) =>
  getInputError ? (
    <EditableCellError width={column.width}>
      {getInputError(tableRow, column)}
    </EditableCellError>
  ) : null;

const EditableCell = ({
  children,
  input,
  getInputName,
  isEditable,
  getInputError,
  openNextFallback,
  openPrevFallback,
  ...props
}) => {
  const { column, row, tableRow } = props;
  const { editableOptions = {}, OuterComponent = () => null } = column;
  const { formatValue } = editableOptions;
  const columnId = column.columnId;
  const rowId = tableRow.rowId;

  const { addCell, removeCell, setCurrentCell, currentCell } = useContext(
    WithEditableCellContext
  );

  const anchorEl = useRef();
  const isVisible =
    currentCell.columnId === columnId && currentCell.rowId === rowId;

  useEffect(() => {
    addCell(rowId, columnId);
    return () => removeCell(rowId, columnId);
  }, [rowId, columnId, addCell, removeCell]);

  const startEditMode = useCallback(() => {
    setCurrentCell({ rowId, columnId });
  }, [setCurrentCell, columnId, rowId]);

  const handleChange = useCallback(
    async (value, field, form) => {
      const { editableOptions = {} } = column;
      const { beforeSave, afterSave } = editableOptions;
      value = beforeSave ? beforeSave(value, field) : value;

      const newValues = setIn({ ...form.values }, field.name, value);
      // Do not change the value in formik state unless it passes validation, otherwise revert to original
      await form.validateForm(newValues).then(errors => {
        let newValue = field.value;
        if (errors[field.name]) {
          startEditMode();
        } else {
          newValue = value;
        }
        form.setFieldValue(field.name, newValue, false);
        afterSave && afterSave(newValue, { field, rowId, ...form });
      });
    },
    [column, rowId, startEditMode]
  );

  const { onClick, onKeyUp } = buildPressEventHandlers(
    startEditMode,
    false,
    false,
    []
  );

  const displayError = displayFieldError(getInputError, tableRow, column);

  return (
    <BWCell {...props} onChange={undefined}>
      <EditableWrapper ref={anchorEl} onClick={onClick} onKeyUp={onKeyUp}>
        {children}
      </EditableWrapper>
      <OuterComponent row={row} />
      {displayError}
      <Field name={getInputName(tableRow, column)}>
        {({ field, form }) => (
          <FloatInput
            input={input}
            onChange={value => handleChange(value, field, form)}
            autoFocus
            name={field.name}
            value={
              field.value
                ? getInitialValue(formatValue, field.value)
                : row[column.name]
            }
            visible={isVisible}
            hasError={hasError(input.name, form)}
            anchorEl={() => anchorEl.current}
            openNextFallback={openNextFallback}
            openPrevFallback={openPrevFallback}
          />
        )}
      </Field>
    </BWCell>
  );
};
EditableCell.propTypes = {
  input: RawFloatInput.propTypes.input,
  getInputName: PropTypes.func.isRequired,
  isEditable: PropTypes.func,
};
EditableCell.defaultProps = {
  input: {},
  getInputName: () => {},
  isEditable: () => {},
};

export default EditableCell;

const getInput = ({ rowInputs, tableRow, name }) => {
  return isFunction(rowInputs) ? rowInputs(tableRow)[name] : rowInputs[name];
};

export function editableCellCreator(
  rowInputs,
  getInputName,
  isEditable,
  getInputError
) {
  return function CellWrapper({ children, ...props }) {
    const { column, tableRow } = props;
    if (isEditable && !isEditable(column, tableRow))
      return <BWCell {...props}>{children}</BWCell>;

    if (!column.editable) return <BWCell {...props}>{children}</BWCell>;
    const input = getInput({ rowInputs, tableRow, name: column.name });
    return (
      <EditableCell
        {...props}
        input={input}
        getInputName={getInputName}
        getInputError={getInputError}
      >
        {children}
      </EditableCell>
    );
  };
}
