import React from "react";

import propTypes from "../../../../../constants/propTypes";
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter";
import { ColumnItem } from "../Fees/FeeSummary/FeeSummary";

const format = (value, currencyFormatter) =>
  currencyFormatter.format(value || 0);

const AccountSection = ({
  project: { totalFundsReceived, expenditures },
  projectForecast: {
    totalReceived,
    totalForecastAmount,
    uncommittedPrice,
    committedPrice,
  },
  currency = {},
}) => {
  const currencyFormatter = useCurrencyFormatter(currency);
  const values = [
    [
      {
        title: "Currency",
        value: currency.name,
      },
      {
        title: "Total Funds Received",
        value: format(totalReceived, currencyFormatter),
      },
    ],
    [
      {
        title: "Conv. Rate",
        value: "1.00",
      },

      {
        title: "Expenditures",
        value: format(expenditures, currencyFormatter),
      },
    ],
    [
      {
        title: "Forecast",
        value: format(totalForecastAmount, currencyFormatter),
      },
      { title: "Committed", value: format(committedPrice, currencyFormatter) },
    ],
    [
      {
        title: "Cash Balance",
        value: format(totalFundsReceived - expenditures, currencyFormatter),
      },
      {
        title: "Uncommitted",
        value: format(uncommittedPrice, currencyFormatter),
      },
    ],
  ];

  return (
    <div>
      {values.map((row, key) => (
        <ColumnItem row={row} key={key} id={key} />
      ))}
    </div>
  );
};

AccountSection.defaultProps = {
  project: {
    currency: {},
  },
};

AccountSection.propTypes = {
  project: propTypes.project,
  projectForecast: propTypes.projectForecast,
  currency: propTypes.currency,
};

export default AccountSection;
