import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { getDataComponentFlattenedRequestState } from "../../reducers/dataComponentReducer";
import { getBluechipResources } from "../../utils/bluechipUtils";
import { getSpecShipmentsDataComponentId } from "../pages/ProjectDetail/Shipments/SpecShipments/SpecShipmentsContainer";

/**
 * When specShipments is available, rely on it to decide if it's delivered:
 *  - this will ensure changes will reflect immediately,
 *  - otherwise the purchaseOrder would need to be refreshed to reflect the changes
 * When specShipments is not available, rely on the purchaseOrder (withShipmentsData modifier) data
 */
const isPODelivered = (
  { shipmentsCount, shipmentsWithoutDelivery },
  specShipments
) =>
  isEmpty(specShipments)
    ? shipmentsCount > 0 && shipmentsWithoutDelivery == 0
    : specShipments.some(({ shipmentsCount }) => shipmentsCount > 0) &&
      specShipments.every(
        ({ shipmentsWithoutDelivery }) => shipmentsWithoutDelivery == 0
      );

export const usePODelivered = purchaseOrder => {
  const specShipmentsDataComponentId = getSpecShipmentsDataComponentId(
    purchaseOrder
  );
  const { specShipments, isLoading } = useSelector(state => {
    const flattenedDataComponent = getDataComponentFlattenedRequestState(
      specShipmentsDataComponentId,
      state
    );
    return {
      flattenedDataComponent,
      specShipments: getBluechipResources(flattenedDataComponent, state) || [],
      isLoading: flattenedDataComponent.loading,
    };
  });
  const poHasShipmentsData =
    "shipmentsCount" in purchaseOrder &&
    "shipmentsWithoutDelivery" in purchaseOrder;
  return {
    isDelivered: isPODelivered(purchaseOrder, specShipments),
    isLoading: !poHasShipmentsData && isLoading,
  };
};
