import jwt from "jsonwebtoken";
import get from "lodash.get";
import moment from "moment-timezone";

import ApiService from "./ApiService";
import { getMyIp, getMyLocation } from "./utils";

const scopeTimezone = {
  USA: "America/Denver",
  UK: "Europe/London",
};

export default class AuthService {
  constructor(autoDeserialize = true, service = new ApiService(false)) {
    this.service = service;
    this.autoDeserialize = autoDeserialize;
  }

  async verifyEmail(email) {
    const data = { email };
    const response = await this.service.post("/auth/verify-email", data);
    return response.data;
  }

  async login(email, password) {
    const ip = await getMyIp();
    const location = await getMyLocation(ip);
    const data = { email, password, ip, location };
    const response = await this.service.post("/auth/login", data);
    return response.data;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
  }

  storeToken(token) {
    return localStorage.setItem("auth_token", token);
  }

  getToken() {
    return localStorage.getItem("auth_token");
  }

  getTokenInformation() {
    return jwt.decode(this.getToken());
  }

  async getAuthenticatedUserInformation() {
    const listUser = await (
      await this.service.get(
        "/users/me",
        {
          includes: JSON.stringify([
            "profilePicture",
            "userRole.permissions",
            "office.scope",
          ]),
        },
        this._authHeaders()
      )
    ).deserializeData();

    const user = get(listUser, "0");
    const scope = get(user, "office.scope.name");
    moment.tz.setDefault(get(scopeTimezone, scope));

    return user;
  }

  async get(path, getParameters = {}, headers = {}, options = {}) {
    let response = await this.service.get(
      path,
      getParameters,
      {
        ...this._authHeaders(),
        ...headers,
      },
      options
    );
    if (this.autoDeserialize) {
      response = await response.deserializeData();
    }
    return response;
  }

  async post(path, data, getParameters = {}, headers = {}, options = {}) {
    let response = await this.service.post(
      path,
      data,
      getParameters,
      {
        ...this._authHeaders(),
        ...headers,
      },
      options
    );

    if (this.autoDeserialize) {
      response = await response.deserializeData();
    }
    return response;
  }

  async patch(path, data, getParameters = {}, headers = {}, options = {}) {
    let response = await this.service.patch(
      path,
      data,
      getParameters,
      {
        ...this._authHeaders(),
        ...headers,
      },
      options
    );
    if (this.autoDeserialize) {
      response = await response.deserializeData();
    }
    return response;
  }

  async put(path, data, getParameters = {}, headers = {}, options = {}) {
    let response = await this.service.put(
      path,
      data,
      getParameters,
      {
        ...this._authHeaders(),
        ...headers,
      },
      options
    );
    if (this.autoDeserialize) {
      response = await response.deserializeData();
    }
    return response;
  }

  async delete(path, getParameters = {}, headers = {}, body) {
    let response = await this.service.delete(
      path,
      getParameters,
      {
        ...this._authHeaders(),
        ...headers,
      },
      body
    );
    return response;
  }

  _authHeaders() {
    var headers = {};
    const token = this.getToken();
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    return headers;
  }
}
