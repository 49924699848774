import React from "react";
import _noop from "lodash/noop";
import memoize from "memoize-one";
import styled from "styled-components";
import Delete from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
const BWModels = require("benjaminwest-models");

import { inputs } from "../../../inputs/inputConfigs";
import InputLabel from "../../../mui/core/InputLabel";
import ProjectNumberHelper from "./ProjectNumberHelper";
import { BWActionButton } from "../../../ui/BWActionButton";
import FeeScheduleAction from "./FeeScheduleAction";
import { WorkScopeFields } from "./WorkScopeFields";

export const Button = styled(props => (
  <BWActionButton disableRipple disableFocusRipple {...props} />
))`
  padding: 0;
  margin-left: 24px;
  &:hover {
    background-color: unset;
  }
  &:last-child {
    margin-right: 8px;
  }
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const getClientPricingInput = (
  clientId,
  clientPricing,
  projectTier
) => ({
  ...inputs.clientPricing,
  APIOptions: {
    ...inputs.clientPricing.APIOptions,
    rootFilters: {
      $where: {
        clientId,
        version: {},
      },
    },
  },
  labelProperty: ({ id, version }) =>
    `Version ${version}${
      id == clientPricing?.id && projectTier
        ? ` (Tier ${projectTier.sequenceIndex})`
        : ""
    }`,
});

export const basicContractGroup = ({
  hasContracts,
  canUpdateContractedProjectNameScope,
}) => {
  const baseInputs = [
    {
      input: {
        ...inputs.projectName,
        disabled: !canUpdateContractedProjectNameScope,
      },
      grid: { xs: 4 },
    },
    {
      input: {
        ...inputs.projectNumber,
        helperText: <ProjectNumberHelper />,
      },
      grid: { xs: 4 },
    },
  ];

  if (hasContracts) {
    baseInputs.push({
      input: inputs.projectNumberExt,
      grid: { xs: 4 },
    });
  }

  return {
    groups: [
      {
        items: baseInputs,
      },
    ],
  };
};

// eslint-disable-next-line complexity
const getFeeBasisFields = ({
  feeBasis,
  feePercent,
  contractBudget,
  numberOfPOs,
  feesPerPurchaseOrder,
  clientPricing,
  clientId,
}) => {
  const Project = BWModels.loadSchema("Project");
  const projectTier = Project.tierPricingHelpers.getProjectTier(
    { contractBudget },
    clientPricing
  );
  switch (feeBasis) {
    case "Fixed":
      return [{ input: inputs.projectFeeAmount, grid: { xs: 4 } }];
    case "Percentage":
      return [
        { input: inputs.projectFeePercent, grid: { xs: 4 } },
        {
          input: {
            ...inputs.projectFees,
            value: contractBudget * ((feePercent || 0) / 100),
            InputProps: {
              ...inputs.projectFees.InputProps,
              onChange: _noop,
              disabled: true,
            },
          },
          grid: { xs: 4 },
        },
      ];
    case "Limit":
      return [
        { input: inputs.projectBaseAmountLimit, grid: { xs: 4 } },
        { input: inputs.projectUpperSpendLimit, grid: { xs: 2 } },
        { input: inputs.projectOverLimitFee, grid: { xs: 2 } },
      ];
    case "PO":
      return [
        { input: inputs.projectNumberOfPOs, grid: { xs: 4 } },
        { input: inputs.projectFeesPerPO, grid: { xs: 2 } },
        {
          input: {
            ...inputs.projectFees,
            value: numberOfPOs * feesPerPurchaseOrder,
            InputProps: {
              ...inputs.projectFees.InputProps,
              onChange: _noop,
              disabled: true,
            },
          },
          grid: { xs: 2 },
        },
      ];
    case "Tiered Pricing":
      return [
        {
          input: getClientPricingInput(clientId, clientPricing, projectTier),
          grid: { xs: 4 },
        },
        {
          input: {
            ...inputs.projectFees,
            InputProps: {
              ...inputs.projectFees.InputProps,
              onChange: _noop,
              disabled: true,
              value: projectTier
                ? Project.tierPricingHelpers.getTierFeeAmount(
                    { contractBudget },
                    clientPricing
                  )
                : 0,
            },
          },
          grid: { xs: 2 },
        },
      ];
    default:
      return [];
  }
};

export const getRevenueSharingFields = values => {
  const shouldEnableRevenueSharingFields =
    values.workScopes.find(ws => ws.name === "OS&E") &&
    values.feeBasis &&
    !values.feeScheduleGenerated;
  return [
    {
      input: {
        ...inputs.revenueSharing,
        disabled: !shouldEnableRevenueSharingFields,
      },
      grid: { xs: 4, noPaddingLeft: true },
    },
    {
      input: {
        ...inputs.project,
        isDisabled: !values.revenueSharing || !shouldEnableRevenueSharingFields,
        name: "revenueSharingProjectId",
        APIOptions: {
          ...inputs.project.APIOptions,
          rootFilters: {
            $where: {
              id: {
                $notEqual: values.id,
              },
            },
          },
        },
      },
      grid: { xs: 4 },
    },
    { grid: { xs: 4 } },
  ];
};

export const getFeeBasisFieldsWithRules = (values, extraConfig) => {
  const feeBasisFields = [...getFeeBasisFields(values)];
  return feeBasisFields.map(field => {
    field.input = { ...field.input, ...extraConfig };
    return field;
  });
};

const getWaiverFields = (isWaiverRequired, projectId) => {
  return isWaiverRequired
    ? [
        {
          input: {
            ...inputs.projectWaiverFile,
            inputFileProps: {
              ...inputs.projectWaiverFile.inputFileProps,
              isForm: !projectId,
            },
          },
          grid: { xs: 4 },
        },
      ]
    : [];
};

export const getCurrencyLabel = (pcItem, index) => {
  return pcItem.isDefault ? "Default Currency" : `Alternate Currency ${index}`;
};

export const getConversionInput = (pcItem, index, isDisabled) => {
  const inputProp = { grid: { xs: 4 } };
  if (!pcItem.isDefault) {
    inputProp.input = {
      ...inputs.conversionRate,
      label: `Conversion Rate`,
      name: `projectCurrencies[${index}].conversionRate`,
      disabled: isDisabled,
      required: true,
      avoidDebounce: true,
    };
  }
  return inputProp;
};

export const getConversionDetail = (
  defaultProjectCurrency,
  pcItem,
  currencies
) => {
  const inputProps = {
    grid: { xs: 2 },
  };

  if (
    pcItem.isDefault ||
    !defaultProjectCurrency.currencyId ||
    !pcItem.currencyId
  )
    return inputProps;

  const defaultIsoCode = currencies.find(
    currency => currency.id === defaultProjectCurrency.currencyId
  ).isoCode;
  const defaultFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: defaultIsoCode,
    minimumFractionDigits: 6,
  });

  const isoCode = currencies.find(currency => currency.id === pcItem.currencyId)
    .isoCode;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: isoCode,
    minimumFractionDigits: 0,
  });

  inputProps.element = (
    <div
      style={{
        height: "100%",
        alignContent: "center",
        fontWeight: "bold",
        fontSize: "13px",
      }}
    >
      {formatter.format(1)} = {defaultFormatter.format(pcItem.conversionRate)}
    </div>
  );

  return inputProps;
};

export const addAlternateCurrency = (projectCurrencies, updateCurrencies) => {
  const updatedCurrencies = [
    ...projectCurrencies,
    {
      currencyId: null,
      conversionRate: null,
      isDefault: false,
    },
  ];
  updateCurrencies(updatedCurrencies);
};

export const deleteAlternateCurrency = (
  index,
  projectCurrencies,
  updateCurrencies
) => {
  let updatedCurrencies = [...projectCurrencies];
  updatedCurrencies.splice(index, 1);
  updateCurrencies(updatedCurrencies);
};

export const getCurrencyAction = (
  projectCurrencies,
  pcItem,
  updateCurrencies,
  index,
  canUpdateProjectCurrencies
) => {
  const input = { grid: { xs: 2 } };
  if (pcItem.isDefault) {
    input.element = (
      <ActionButtonWrapper>
        <Button
          onClick={() =>
            addAlternateCurrency(projectCurrencies, updateCurrencies)
          }
          icon={<AddCircleIcon />}
          text={"Add"}
          disableIfProjectClosed
        />
      </ActionButtonWrapper>
    );
  } else if (!pcItem.isDefault && !pcItem.id && canUpdateProjectCurrencies) {
    input.element = (
      <ActionButtonWrapper>
        <Button
          onClick={() =>
            deleteAlternateCurrency(index, projectCurrencies, updateCurrencies)
          }
          icon={<Delete style={{ color: "#ff1744" }} />}
          text={"Delete"}
          intent={"danger"}
          disableIfProjectClosed
        />
      </ActionButtonWrapper>
    );
  }
  return input;
};

export const buildCurrencyFilter = (projectCurrencies, selectedPC) => {
  const filter = memoize(currencies => {
    if (!currencies) return [];
    const projectCurrencyIds = projectCurrencies
      .map(pcItem => pcItem.currencyId)
      .filter(pcItemId => pcItemId !== selectedPC.currencyId);
    return currencies.filter(cItem => !projectCurrencyIds.includes(cItem.id));
  });
  return currencies => filter(currencies);
};

const getCurrencyFields = (values, updateCurrencies, handleCurrencyChange) => {
  if (values.projectCurrencies) {
    const defaultProjectCurrency = values.projectCurrencies.find(
      pcItem => pcItem.isDefault
    );
    return values.projectCurrencies
      .map((pcItem, index) => {
        const isDisabled =
          values.isUpdate && pcItem.id && !values.canUpdateProjectCurrencies;
        return [
          {
            input: {
              ...inputs.currency,
              label: getCurrencyLabel(pcItem, index),
              name: `projectCurrencies[${index}].currencyId`,
              nullAsEmpty: false,
              displayEmpty: false,
              isDisabled,
              required: true,
              filter: buildCurrencyFilter(values.projectCurrencies, pcItem),
              handleChange: handleCurrencyChange(pcItem),
            },
            grid: { xs: 4 },
          },
          getConversionInput(pcItem, index, isDisabled),
          getConversionDetail(
            defaultProjectCurrency,
            pcItem,
            values.currencies
          ),
          isDisabled
            ? {}
            : getCurrencyAction(
                values.projectCurrencies,
                pcItem,
                updateCurrencies,
                index,
                values.canUpdateProjectCurrencies
              ),
        ];
      })
      .reduce((allInputs, currencyInputs) => {
        return allInputs.concat(currencyInputs);
      }, []);
  }
  return [];
};

export const detailsContractGroup = (
  props,
  updateCurrencies,
  isFeeBasisOptionDisabled,
  scopeId
) => {
  const {
    isWaiverRequired,
    startDate,
    canChangeFeeInformation,
    currency,
    canUpdateContractedProjectNameScope,
    canShowSubScopeNoteToggle,
    canShowEnableFeeSummaryCalculationUpdate,
    id,
    handleCurrencyChange,
    ...values
  } = props;
  // isDisabled for some input types and disabled for the rest.
  const feeInputsExtraConfiguration = canChangeFeeInformation
    ? {
        isDisabled: values.feeScheduleGenerated,
        disabled: values.feeScheduleGenerated,
      }
    : {
        isDisabled: true,
        disabled: true,
        helperText:
          "You are not authorized to make this change. Please consult with your RPM2 admin",
      };

  return {
    groups: [
      {
        items: [
          {
            element: <InputLabel>Select Scope</InputLabel>,
            grid: { xs: 6, style: { alignSelf: "center" } },
          },
          {
            input: canShowSubScopeNoteToggle
              ? inputs.publicSubScopeNotes
              : null,
            grid: { xs: 6, style: { textAlign: "end" } },
          },
          {
            element: (
              <WorkScopeFields
                workScopeOptions={props.workScopeOptions || []}
              />
            ),
            grid: { xs: 12 },
          },
        ],
      },
      {
        items: [
          {
            input: { ...inputs.startDate, disablePast: false },
            grid: { xs: 4 },
          },
          { input: { ...inputs.endDate, minDate: startDate }, grid: { xs: 4 } },
          { grid: { xs: 12 } },
          {
            input: {
              ...inputs.office,
              label: "BW Local Office",
              APIOptions: {
                ...inputs.office.APIOptions,
                omitFilterScopeModels: true,
                rootFilters: {
                  $where: {
                    scopeId,
                  },
                },
              },
            },
            grid: { xs: 4 },
          },
          {
            input: inputs.projectService,
            grid: { xs: 4 },
          },
          {
            input: inputs.projectType,
            grid: { xs: 4 },
          },
        ],
      },
      {
        items: getCurrencyFields(
          values,
          updateCurrencies,
          handleCurrencyChange
        ),
      },
      getFeeGroup(
        values,
        feeInputsExtraConfiguration,
        currency,
        isFeeBasisOptionDisabled,
        canShowEnableFeeSummaryCalculationUpdate
      ),
      {
        items: [
          {
            input: inputs.projectIsWaiverRequired,
            grid: { xs: 12, noPaddingLeft: true },
          },
          ...getWaiverFields(isWaiverRequired, id),
          {
            input: inputs.projectIncludeInMarketingMaterials,
            grid: { xs: 12, noPaddingLeft: true },
          },
        ],
      },
    ],
  };
};

export const getFeeGroup = (
  values,
  feeInputsExtraConfiguration,
  currency,
  isFeeBasisOptionDisabled,
  canShowEnableFeeSummaryCalculationUpdate
) => {
  return {
    items: [
      {
        input: {
          ...inputs.projectContractBudget,
          inputProps: { currency },
        },
        grid: { xs: 4 },
      },
      { input: inputs.projectVariance, grid: { xs: 4 } },
      {
        input: {
          ...inputs.projectShouldTotalIncludeAllSpecs,
          disabled: !canShowEnableFeeSummaryCalculationUpdate,
        },
        grid: { xs: 4 },
      },
      ...getRevenueSharingFields(values),
      {
        input: {
          ...inputs.contractFeeBasis,
          ...feeInputsExtraConfiguration,
          isOptionDisabled: isFeeBasisOptionDisabled,
        },
        grid: { xs: 4 },
      },
      ...getFeeBasisFieldsWithRules(values, feeInputsExtraConfiguration),
      { grid: { xs: 12 } },
      {
        input: {
          ...inputs.contractBillingTerm,
          ...feeInputsExtraConfiguration,
        },
        grid: { xs: 4 },
      },
      { grid: { xs: 4 } },
      {
        element: <FeeScheduleAction clientId={values.clientId} />,
        grid: { xs: 4 },
      },
      {
        input: {
          ...inputs.projectExpectedFeeCount,
          ...feeInputsExtraConfiguration,
        },
        grid: { xs: 4 },
      },
      {
        input: {
          ...inputs.projectFeeBillingStartMonth,
          ...feeInputsExtraConfiguration,
        },
        grid: { xs: 4 },
      },
      {
        input: {
          ...inputs.projectFeeBillingDueDate,
          ...feeInputsExtraConfiguration,
        },
        grid: { xs: 4 },
      },
    ],
  };
};
