import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import { CreateProjectService } from "./CreateProjectService";
import {
  performCreateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import { projectServiceDataComponentId } from "../ProjectServiceContainer";
import { useCreateProcessRequests } from "../../../../hooks/useProcessRequest";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { convertServerErrorToFormikErrors } from "../../../../../utils/formValidationUtils";
import propTypes from "../../../../../constants/propTypes";

const CreateProjectServiceContainer = ({
  dataComponent,
  performCreateRequest,
  showSnackNotificationAction,
  setReload,
  closeModalDialog,
}) => {
  useCreateProcessRequests(dataComponent, {
    onSuccess: () => {
      showSnackNotificationAction("Service Type created successfully.");
      setReload(projectServiceDataComponentId, true);
      closeModalDialog();
    },
    onError: error => {
      const convertedError = convertServerErrorToFormikErrors(error);
      showSnackNotificationAction(convertedError.name);
    },
  });

  const handleCreateProjectService = values => {
    performCreateRequest(projectServiceDataComponentId, values);
  };

  return (
    <CreateProjectService
      handleCreateProjectService={handleCreateProjectService}
      closeModalDialog={closeModalDialog}
    />
  );
};

CreateProjectServiceContainer.propTypes = {
  dataComponent: propTypes.dataComponent,
  performCreateRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    dataComponent: getDataComponent(projectServiceDataComponentId, state),
  };
};

const mapDispatchToProps = {
  performCreateRequest,
  showSnackNotificationAction,
  setReload,
  closeModalDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProjectServiceContainer);
