import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _last from "lodash/last";
import _get from "lodash/get";
import styled from "styled-components";

import Control from "./AutocompleteControl";
import Option from "./Option";
import MultiValue from "./MultiValue";
import {
  NoOptionsMessage,
  Placeholder,
  ValueContainer,
  DropdownIndicator,
  IndicatorSeparator,
  Input,
  ClearIndicator,
} from "./components";
import {
  PORevisionContext,
  RevisionReferenceContext,
} from "../../../../withPORevision";
import { calculateReference } from "../../../../withPORevision/withRevisionReference";
import { escapeStringRegexp } from "../../../../utils/stringUtils";
import {
  getLabel,
  getNullAsEmptyValue,
  optionsWithEmptyValue,
} from "../StandardSelect";
import {
  getRequiredStyle,
  onInputChangeWrapper,
} from "../SingleAutoCompleteSelect/components";

const StyledSelect = styled(Select)`
  ${props => getRequiredStyle(props)}
`;

const defaultComponents = {
  Control,
  MultiValue,
  Option,
  ValueContainer,
  IndicatorSeparator,
  DropdownIndicator,
  NoOptionsMessage,
  Input,
  ClearIndicator,
};

export const filterOption = labelProperty => (option, search) => {
  const label = getLabel(labelProperty, option.data);
  return new RegExp(escapeStringRegexp(search), "ig").test(label);
};

export const buildChangeHandler = (
  isAutocompleteMulti,
  submitData,
  name,
  onChange,
  valueProperty = "id",
  nullAsEmpty,
  handleChange
) => data => {
  const items = submitData ? data : data.map(item => item[valueProperty]);
  const lastItem = _last(items);
  const value = isAutocompleteMulti ? items : lastItem;
  const event = {
    type: "click",
    target: {
      name,
      value: getNullAsEmptyValue({ value: value || "", nullAsEmpty }),
    },
  };

  const omitOnChange = handleChange && handleChange(event);
  if (!omitOnChange) {
    onChange(event);
  }
};

const customOnBlur = (name, onBlur) => evt =>
  onBlur({ ...evt, target: { ...evt.target, name } });

const getValue = (
  isAutocompleteMulti,
  isSubmittingData,
  value,
  options,
  valueProperty
) => {
  if (!value) return [];
  const arr = isAutocompleteMulti && Array.isArray(value) ? value : [value];

  if (isSubmittingData) {
    return arr;
  }
  return arr.map(value =>
    options.find(option => option[valueProperty] === value)
  );
};

export const getInputLabelProps = (
  value,
  { label, error, helperText, textFieldProps = {} },
  wasUpdated
) => {
  return {
    label,
    error,
    helperText,
    wasUpdated,
    ...textFieldProps,
  };
};

export const getComponents = (label, customComponents) => ({
  ...defaultComponents,
  Placeholder: label ? () => "" : Placeholder,
  ...customComponents,
});

export const isOptionDisabled = option =>
  _get(option, "menuItemProps.disabled");

const useMenuOpen = (defaultOption, openCallback = () => {}) => {
  const [isOpen, setIsOpen] = useState(defaultOption);

  const handleMenuOpen = () => {
    if (!isOpen) {
      setIsOpen(true);
      openCallback("");
    }
  };

  const handleMenuClose = () => {
    setIsOpen(false);
  };
  return [handleMenuClose, handleMenuOpen];
};

export const AutocompleteSelect = ({
  onChange,
  value,
  valueProperty,
  labelProperty,
  onBlur,
  options: initialOptions,
  nullAsEmpty,
  onMenuOpen,
  components: customComponent = {},
  handleChange,
  ...props
}) => {
  const [inputValue, setInputValue] = useState();

  const { isActive, isUpdated, hasModifiedChildrens } = useContext(
    PORevisionContext
  );
  const [handleMenuClose, handleMenuOpen] = useMenuOpen(false, onMenuOpen);
  const { reference } = useContext(RevisionReferenceContext);

  const options = useMemo(
    optionsWithEmptyValue(
      valueProperty,
      labelProperty,
      initialOptions,
      false,
      nullAsEmpty
    ),
    [valueProperty, labelProperty, initialOptions, props.required, nullAsEmpty]
  );

  const wasModified = useMemo(() => {
    if (!isActive) return false;
    if (props.isAutocompleteMulti) {
      return hasModifiedChildrens(
        calculateReference(reference, props.name).reference
      );
    }
    return isUpdated(reference, props.name);
  }, [
    isActive,
    isUpdated,
    hasModifiedChildrens,
    reference,
    props.name,
    props.isAutocompleteMulti,
  ]);

  const parsedValue = getValue(
    props.isAutocompleteMulti,
    props.submitData,
    value,
    options,
    valueProperty
  );
  const components = useMemo(
    () => getComponents(props.label, customComponent),
    [props.label, customComponent]
  );

  return (
    <StyledSelect
      options={options}
      components={components}
      isMulti={true}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      onChange={buildChangeHandler(
        props.isAutocompleteMulti,
        props.submitData,
        props.name,
        onChange,
        valueProperty,
        nullAsEmpty,
        handleChange
      )}
      onBlur={onBlur && customOnBlur(props.name, onBlur)}
      hideSelectedOptions={true}
      value={parsedValue}
      filterOption={filterOption(labelProperty)}
      getOptionValue={data => {
        return data[valueProperty];
      }}
      textFieldProps={getInputLabelProps(value, props, wasModified)}
      getOptionLabel={data => getLabel(labelProperty, data)}
      isOptionDisabled={isOptionDisabled}
      {...props}
      inputValue={inputValue}
      isDisabled={props.isLoading}
      onInputChange={onInputChangeWrapper(props.onInputChange, setInputValue)}
    />
  );
};

AutocompleteSelect.defaultProps = {
  labelProperty: "name",
  valueProperty: "id",
  submitData: false,
};

AutocompleteSelect.propTypes = {
  submitData: PropTypes.bool,
  isAutocompleteMulti: PropTypes.bool,
  valueProperty: PropTypes.string,
  labelProperty: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  nullAsEmpty: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
};

export default AutocompleteSelect;
