import React, { memo } from "react";
import { useFormikContext } from "formik";
import get from "lodash.get";

export const addDirtyInputMark = fields => {
  fields.groups.forEach(group => {
    group.items.forEach(item => {
      if (item.input?.InputComponent) {
        item.input.InputComponent = withMarkDirtyInput(
          item.input.InputComponent,
          item.input.name
        );
      }
    });
  });

  return fields;
};

function withMarkDirtyInput(InputComponent, name) {
  const WrappedComponent = props => {
    const { initialValues, values, touched } = useFormikContext();

    const wasUpdated =
      get(touched, name) || get(initialValues, name) != get(values, name);

    return <InputComponent {...props} wasUpdated={wasUpdated} />;
  };
  return memo(WrappedComponent);
}
export default withMarkDirtyInput;
