import React, { Component } from "react";
import PropTypes from "prop-types";
import propTypes from "../../../constants/propTypes";

import ProjectForm from "./ProjectForm";
import { Content } from "../../layout/Content";
import { dataComponentId } from "./ProjectCreateContainer";

export default class ProjectCreatePage extends Component {
  projectInitialValues = {
    propertyId: undefined,
    status: "",
    endDate: null,
    startDate: null,
    name: "",
    officeId: undefined,
    projectCurrencies: [
      { currencyId: null, conversionRate: 1.0, isDefault: true },
    ],
    fundingDue: undefined,
    fundingType: undefined,
    number: undefined,
    fundingDueDate: undefined,
    fundingDueWeekday: undefined,
    contractBudget: undefined,
    feeBasis: undefined,
    feeAmount: undefined,
    upperSpendLimit: undefined,
    overLimitFee: undefined,
    fees: undefined,
    expectedFeeCount: undefined,
    hasAccounting: undefined,
    accountingBankAccount: undefined,
    isFreightTaxable: undefined,
    isInstallTaxable: undefined,
    processingDay: "Weekly",
    accountingDueDate: undefined,
    accountingDueDay: undefined,
    freightVendorId: undefined,
    projectMemberUsers: [],
    projectMemberContacts: [],
    areas: [],
    contingencies: [
      { contingencyType: "Merchandise", name: "Freight", unit: "%", value: 10 },
      {
        contingencyType: "Merchandise",
        name: "Warehouse",
        unit: "%",
        value: 10,
      },
      {
        contingencyType: "Merchandise",
        name: "Contingency",
        unit: "%",
        value: 5,
      },
    ],
    addresses: [],
    contractFiles: [],
    sites: [],
    workScopes: [],
    shouldTotalIncludeAllSpecs: true,
  };

  render() {
    const {
      createProject,
      onCreateArea,
      onDeleteArea,
      onUpdateArea,
      clientId,
      project,
      loading,
      currencies,
      areaTypes,
      client,
      role,
      workScopes,
    } = this.props;
    return (
      <Content>
        <ProjectForm
          client={client}
          role={role}
          clientId={clientId}
          loading={loading}
          onSubmit={createProject}
          onCreateArea={onCreateArea}
          onDeleteArea={onDeleteArea}
          onUpdateArea={onUpdateArea}
          sendButtonText="Create Project"
          initialValues={this.projectInitialValues}
          valueOverrides={{
            varianceType: "Project Forecast Total",
          }}
          project={project}
          areaTypes={areaTypes}
          currencies={currencies}
          workScopes={workScopes}
          dataComponentId={dataComponentId}
        />
      </Content>
    );
  }
}

ProjectCreatePage.propTypes = {
  createProject: PropTypes.func.isRequired,
  onCreateArea: PropTypes.func.isRequired,
  onDeleteArea: PropTypes.func.isRequired,
  onUpdateArea: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  project: PropTypes.object.isRequired,
  areaTypes: PropTypes.array.isRequired,
  client: propTypes.client,
  currencies: PropTypes.array,
  role: propTypes.userRole,
  workScopes: PropTypes.array,
};
