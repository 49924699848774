import React from "react";
import PropTypes from "prop-types";

import { FormPageSection } from "../../../layout/FormPageSection";
import createRemarksFields from "./createRemarksFields";
import { addDirtyInputMark } from "../withMarkDirtyInput";
import { ActionButtons } from "../../../forms";
import { dataComponentId } from "../InvoiceDetailContainer";

const Section = ({
  setCurrentCell,
  updateInvoice,
  allChangesLocked,
  isLoading,
  ...formGridProps
}) => {
  return (
    <FormPageSection
      headerText="Remarks"
      fields={addDirtyInputMark(
        createRemarksFields(formGridProps.checkNumber, setCurrentCell)
      )}
      {...formGridProps}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "0 14px 14px",
        }}
      >
        <ActionButtons
          onSend={updateInvoice}
          sendButtonText="UPDATE"
          disabled={allChangesLocked}
          hideCancelButton={true}
          listeners={[dataComponentId]}
          additionalProps={{
            send: { disableIfProjectClosed: true, isLoading },
          }}
        />
      </div>
    </FormPageSection>
  );
};

Section.propTypes = {
  setCurrentCell: PropTypes.func.isRequired,
};

export default Section;
