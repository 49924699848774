import React, { memo, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import { PORevisionContext } from "./wrapPORevision";
import { RevisionReferenceContext } from "./withRevisionReference";

/**
 * A public higher-order component to use the PO revision state
 */
function connectInput(WrappedComponent) {
  const ConnectedInput = props => {
    const { isActive, isUpdated } = useContext(PORevisionContext);
    const { reference } = useContext(RevisionReferenceContext);

    const name = _get(props, "inputProps.name", props.name);

    const additionalProps = useMemo(() => {
      if (!isActive || !name) {
        return {};
      }

      const realName = name.replace("Local", "");
      return {
        wasUpdated: isUpdated(reference, realName),
      };
    }, [isUpdated, reference, name, isActive]);

    return <WrappedComponent {...props} {...additionalProps} />;
  };

  ConnectedInput.propTypes = {
    name: PropTypes.string,
  };

  return memo(ConnectedInput);
}
export default connectInput;
