import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { isEqual } from "lodash";

import propTypes from "../../../../../constants/propTypes";
import TopDetails from "./TopDetails";
import RelatedSpecs from "./RelatedSpecs";
import SelectCOMInputs from "./SelectCOMInputs";
import { usePrevious } from "../../../../hooks/usePrevious";

const BottomDetails = ({
  handleClickRelatedSpec,
  selectedSpecDetailCom,
  relatedSpecs,
  isSelectCOM,
  addCOMToSpec,
  clientId,
  loading,
  disabled,
  dataComponentId,
}) => {
  return isSelectCOM ? (
    <SelectCOMInputs
      addCOMToSpec={addCOMToSpec}
      selectedSpecDetailCom={selectedSpecDetailCom}
      disabled={disabled}
      dataComponentId={dataComponentId}
    />
  ) : (
    <RelatedSpecs
      specs={relatedSpecs}
      clientId={clientId}
      loading={loading}
      handleClickRelatedSpec={handleClickRelatedSpec}
    />
  );
};

BottomDetails.propTypes = {
  selectedSpecDetailCom: propTypes.specDetailCom,
  handleClickRelatedSpec: PropTypes.func,
  isSelectCOM: PropTypes.bool,
  relatedSpecs: PropTypes.arrayOf(propTypes.relatedSpec),
  addCOMToSpec: PropTypes.func,
  clientId: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  dataComponentId: PropTypes.string.isRequired,
};

const DetailSection = ({
  itemSelected,
  isSelectCOM,
  addCOMToSpec,
  relatedSpecs,
  clientId,
  loading,
  selectedSpecDetailCom,
  handleClickRelatedSpec,
  dataComponentId,
  handleUpdatePreview,
}) => {
  const propSpecDetails = get(
    itemSelected,
    "specDetails",
    []
  ).map(specDetail => ({ ...specDetail, isSelected: true }));
  const [specDetails, setSpecDetails] = useState(propSpecDetails);

  const prevPropSpecDetails = usePrevious(propSpecDetails);
  useEffect(() => {
    if (!isEqual(prevPropSpecDetails, propSpecDetails)) {
      setSpecDetails(propSpecDetails);
    }
  }, [prevPropSpecDetails, propSpecDetails]);

  const handleSpecDetailsUpdate = specDetails => {
    setSpecDetails(specDetails);
  };

  return (
    <Fragment>
      <TopDetails
        loading={loading}
        isSelectCOM={isSelectCOM}
        itemSelected={itemSelected || {}}
        onSpecDetailsUpdate={handleSpecDetailsUpdate}
        handleUpdatePreview={(specDetailId, preview) =>
          handleUpdatePreview(itemSelected, specDetailId, preview)
        }
        specDetails={specDetails}
      />
      <BottomDetails
        disabled={!itemSelected}
        loading={loading}
        clientId={clientId}
        isSelectCOM={isSelectCOM}
        relatedSpecs={relatedSpecs}
        addCOMToSpec={addCOMToSpec(specDetails)}
        handleClickRelatedSpec={handleClickRelatedSpec}
        selectedSpecDetailCom={selectedSpecDetailCom}
        dataComponentId={dataComponentId}
      />
    </Fragment>
  );
};

DetailSection.propTypes = {
  loading: PropTypes.bool,
  clientId: PropTypes.string,
  isSelectCOM: PropTypes.bool,
  itemSelected: propTypes.spec,
  addCOMToSpec: PropTypes.func,
  relatedSpecs: PropTypes.arrayOf(propTypes.relatedSpec),
  initDataComponent: PropTypes.func,
  performFindRequest: PropTypes.func,
  handleClickRelatedSpec: PropTypes.func,
  performRetrieveListRequest: PropTypes.func,
  selectedSpecDetailCom: propTypes.specDetailCom,
  dataComponentId: PropTypes.string.isRequired,
  handleUpdatePreview: PropTypes.func,
};

export default DetailSection;
