import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  closeModalDialog,
  showSnackNotificationAction,
} from "../../../../../actions/layoutActions";
import { EditProjectService } from "./EditProjectService";
import {
  performUpdateRequest,
  setReload,
} from "../../../../../actions/dataComponentActions";
import { useUpdateProcessRequests } from "../../../../hooks/useProcessRequest";
import { getDataComponent } from "../../../../../reducers/dataComponentReducer";
import { convertServerErrorToFormikErrors } from "../../../../../utils/formValidationUtils";
import propTypes from "../../../../../constants/propTypes";
import { projectServiceDataComponentId } from "../ProjectServiceContainer";

const EditProjectServiceContainer = ({
  projectService,
  dataComponent,
  performUpdateRequest,
  showSnackNotificationAction,
  setReload,
  closeModalDialog,
}) => {
  useUpdateProcessRequests(dataComponent, {
    onSuccess: () => {
      showSnackNotificationAction("Service Type saved successfully.");
      setReload(projectServiceDataComponentId, true);
      closeModalDialog();
    },
    onError: error => {
      const convertedError = convertServerErrorToFormikErrors(error);
      showSnackNotificationAction(convertedError.name);
    },
  });

  const handleEditProjectService = values => {
    performUpdateRequest(
      projectServiceDataComponentId,
      projectService.id,
      values
    );
  };

  return (
    <EditProjectService
      projectService={projectService}
      handleEditProjectService={handleEditProjectService}
      closeModalDialog={closeModalDialog}
    />
  );
};

EditProjectServiceContainer.propTypes = {
  projectService: propTypes.projectService.isRequired,
  dataComponent: propTypes.dataComponent,
  performUpdateRequest: PropTypes.func.isRequired,
  showSnackNotificationAction: PropTypes.func.isRequired,
  setReload: PropTypes.func.isRequired,
  closeModalDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    dataComponent: getDataComponent(projectServiceDataComponentId, state),
  };
};

const mapDispatchToProps = {
  performUpdateRequest,
  showSnackNotificationAction,
  setReload,
  closeModalDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProjectServiceContainer);
