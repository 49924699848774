import _get from "lodash/get";

import {
  initDataComponent,
  performRetrieveListRequest,
} from "./dataComponentActions";
import * as DATA_COMPONENTS from "../constants/DataComponents";
import AddressBook from "../models/AddressBook";
import SpecCategory from "../models/SpecCategory";
import VendorCategory from "../models/VendorCategory";
import Brand from "../models/Brand";
import Program from "../models/Program";
import Flag from "../models/Flag";
import PaymentTerm from "../models/PaymentTerm";
import AreaType from "../models/AreaType";
import Currency from "../models/Currency";
import Scope from "../models/Scope";

const defaultFilters = {
  rootFilters: {},
  pageSize: -1,
};

const selects = [
  {
    dataComponentId: DATA_COMPONENTS.ADDRESS_BOOKS_SELECT,
    model: AddressBook,
    includes: [],
    apiRoute: "address-books",
  },
  {
    dataComponentId: DATA_COMPONENTS.SPEC_CATEGORIES_SELECT,
    model: SpecCategory,
    includes: [],
    apiRoute: "spec-categories",
    filters: {
      sort: [{ columnName: "name", direction: "asc" }],
      ...defaultFilters,
      fields: ["spec_categories.id", "name", "key"],
    },
  },
  {
    dataComponentId: DATA_COMPONENTS.VENDOR_CATEGORIES_SELECT,
    model: VendorCategory,
    includes: [],
    apiRoute: "vendor-categories",
    filters: {
      sort: [{ columnName: "name", direction: "asc" }],
      ...defaultFilters,
      fields: ["vendor_categories.id", "name"],
    },
  },
  {
    dataComponentId: DATA_COMPONENTS.BRANDS_SELECT,
    model: Brand,
    includes: [],
    apiRoute: "brands",
    filters: {
      sort: [{ columnName: "name", direction: "asc" }],
      ...defaultFilters,
      fields: ["brands.id", "name"],
    },
  },
  {
    dataComponentId: DATA_COMPONENTS.PROGRAMS_SELECT,
    model: Program,
    includes: ["flag.brand"],
    apiRoute: "programs",
    filters: {
      sort: [{ columnName: "name", direction: "asc" }],
      ...defaultFilters,
      fields: ["programs.id", "name", "flagId"],
    },
  },
  {
    dataComponentId: DATA_COMPONENTS.FLAGS_SELECT,
    model: Flag,
    includes: [],
    apiRoute: "flags",
    filters: {
      sort: [{ columnName: "name", direction: "asc" }],
      ...defaultFilters,
      fields: ["flags.id", "name", "brandId"],
    },
  },
  {
    dataComponentId: DATA_COMPONENTS.PAYMENT_TERMS_SELECT,
    model: PaymentTerm,
    includes: [],
    apiRoute: "payment-terms",
    filters: {
      sort: [{ columnName: "content", direction: "asc" }],
      ...defaultFilters,
      fields: ["payment_terms.id", "content"],
    },
  },
  {
    dataComponentId: DATA_COMPONENTS.AREA_TYPES_SELECT,
    model: AreaType,
    includes: [],
    apiRoute: "area-types",
    filters: {
      sort: [{ columnName: "name", direction: "asc" }],
      ...defaultFilters,
      fields: ["area_types.id", "name"],
    },
  },
  {
    dataComponentId: DATA_COMPONENTS.CURRENCIES_SELECT,
    model: Currency,
    includes: [],
    apiRoute: "currencies",
    filters: {
      sort: [{ columnName: "id", direction: "asc" }],
      ...defaultFilters,
    },
  },
  {
    dataComponentId: DATA_COMPONENTS.SCOPES_SELECT,
    model: Scope,
    includes: [],
    apiRoute: "scopes",
    filters: {
      sort: [{ columnName: "id", direction: "asc" }],
      ...defaultFilters,
    },
  },
];

export function initializeStore() {
  return async dispatch => {
    selects.forEach(
      ({ dataComponentId, model, includes, apiRoute, filters }) => {
        dispatch(
          initDataComponent(
            dataComponentId,
            model,
            includes,
            apiRoute,
            false,
            "v2"
          )
        );
        dispatch(performRetrieveListRequest(dataComponentId, filters));
      }
    );
  };
}

export function reloadDataComponent(dataComponentId) {
  return performRetrieveListRequest(
    dataComponentId,
    _get(
      selects.find(select => select.dataComponentId === dataComponentId),
      "filters"
    )
  );
}
