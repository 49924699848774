import React from "react";

import { Content } from "../../layout/Content";
import UserRolesTable from "./UserRolesTable";
import Teams from "./Teams";
import BrandsTable from "./BrandsTable";
import OfficesTable from "./OfficesTable";
import Templates from "./Templates";
import Terms from "./GlobalTerms";
import PaymentTerms from "./PaymentTerms";
import UpdatePassword from "./UpdatePassword";
import { roleCan } from "../../../utils/roleUtils";
import propTypes from "../../../constants/propTypes";
import ReportTemplates from "./ReportTemplates";
import RequirementType from "./RequirementType";
import UnitOfMeasure from "./UnitOfMeasure";
import QueryReports from "./QueryReports";
import ServiceType from "./ProjectService";
import ProjectType from "./ProjectType";

const SettingsPage = ({ role }) => (
  <Content>
    <Terms />
    <UserRolesTable />
    {roleCan(role, "manage-teams") ? <Teams /> : null}
    {roleCan(role, "manage-custom-reports") ? <ReportTemplates /> : null}
    {roleCan(role, "manage-custom-reports") ? <QueryReports /> : null}
    <Templates />
    <ServiceType />
    <ProjectType />
    <UnitOfMeasure />
    <RequirementType />
    <OfficesTable />
    <BrandsTable />
    {roleCan(role, "manage-payment-terms") ? <PaymentTerms /> : null}
    {roleCan(role, ["general-settings-reset-password"]) ? (
      <UpdatePassword />
    ) : null}
  </Content>
);

SettingsPage.propTypes = {
  role: propTypes.userRole,
};

export default SettingsPage;
